.validatorContainer {
    border-radius: 5px;
    background-color: var(--ap-light-gray);
    padding: 15px;
    margin-top: 16px;
}

.title {
    font-family: var(--opensans-regular);
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 14px;
}