.normal {
    color: var(--white);
}
.success {
    color: var(--one-select-seagreen-medium);
}
.error {
    color: var(--alert-danger);
}

.stackInner {
    font-size: 1.25em;
}

.stackChild {
    font-size: 0.5em;
}

span {
    font-family: var(--opensans-regular);
    font-size: 12px;
}
