.footer {
  height: 10em;
  width: 100%;
  background-color: var(--sm-ghost-white);
}

.footer span {
  font-size: 14px;
  color: var(--sm-dark-gray);
  font-family: var(--opensans-regular);
}

.footerRow {
  height: 100%;
  display: flex;
  align-items: center;
}
