.videoFrame {
    width: "100%";
    height: "540";
}

.iframeContainer {
    overflow: hidden;
    /* Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625) padding-top: 56.25%;*/
    padding-top: 56.25%;
    position: relative;
}

    .iframeContainer iframe {
        height: 90%;
        width: 90%;
        border: 0;
        left: 5%;
        position: absolute;
        top: 0;
    }

.highlight {
    color: #A94442;
}