.text {
    font-family: var(--opensans-semibold);
    font-size: 24px;
}

.btn.btn-primary {
    padding-top: 8px !important;
}

@media only screen and (max-width : 576px) {
    .text {
        font-family: var(--opensans-semibold);
        font-size: 16px;
    }
}
