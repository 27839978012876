.colorbar {
  height: 83px;
  background-color: var(--ap-green);
  background-image: var(--ap-gradient);
}

.colorbarBottom {
  height: 40px;
  background-color: var(--ap-green);
  background-image: var(--ap-gradient);
}

.mainContent {
  /*height: 60vmax;*/
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 150px;
}
