.subText {
  font-family: var(--opensans-regular);
}

.privacy-notice {
  font-size: 20px;
}

.checkbox-label {
  flex-basis: 100%;
  padding-right: 0px !important;
  color: var(--sm-dark-gray);
}

.checkbox-label p {
  color: #000;
  font-family: var(--opensans-regular);
}

.card-columns span {
  font-size: 14px;
}

.form-check-input {
  min-height: 25px !important;
  min-width: 25px !important;
  max-width: 25px;
  accent-color: var(--ap-dark-blue);
  margin-left: 0px !important;
  position: static !important;
}

.checkbox {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
