﻿// This gives us intellisense and gets rid of false-positive warnings about variables & mixins
/// <reference path="../node_modules/bootstrap/scss/bootstrap.scss" />

$font-family-opensans: OpenSans-Regular;
$font-family-opensans-light: OpenSans-Light;
$font-family-opensans-semibold: OpenSans-Semibold;
$font-family-opensans-bold: OpenSans-Bold;

$ap-green: #24a68b;
$ap-light-blue: #00a0cd;
$ap-dark-blue: #007c9f;
$ap-blue-hover: #017494;
$ap-gray: #c9cacd;
$ap-light-gray: #d5d5d6;
$ap-dark-gray: #656668;

$sm-ghost-white: #f9f9f9;
$sm-dark-gray: #5e5f61;

$btn-border-radius: 25px;

$primary: $ap-light-blue;
$link-color: $ap-light-blue;
$link-hover-color: $ap-dark-blue;

$enable-validation-icons: false;

$input-height: 52px;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$headings-color: $ap-green;
$h1-font-size: $font-size-base * 3.25;
$h2-font-size: $font-size-base * 2;

$font-family-base: $font-family-opensans;

$breadcrumb-divider-color: #333333;
$breadcrumb-bg: $sm-ghost-white;
$breadcrumb-active-color: #5e5f61;
$breadcrumb-item-padding: 1rem;
