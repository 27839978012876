.formLoader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999999999;
    background-color: rgba(128, 128, 128, 0.5);
}

.loader {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: 1.1em solid rgba(0, 0, 0, 0.2);
    border-left: 1.1em solid var(--primary-color-green);
    border-radius: 50%;
    animation: defaultSpinner 1.1s infinite linear;
}

@keyframes defaultSpinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.cubeGrid {
    width: 40px;
    height: 40px;
    margin: 100px auto;
}

.cube {
    width: 33%;
    height: 33%;
    background-color: #333;
    float: left;
    animation: cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.cube1 {
    animation-delay: 0.2s;
}

.cube2 {
    animation-delay: 0.3s;
}

.cube3 {
    animation-delay: 0.4s;
}

.cube4 {
    animation-delay: 0.1s;
}

.cube5 {
    animation-delay: 0.2s;
}

.cube6 {
    animation-delay: 0.3s;
}

.cube7 {
    animation-delay: 0s;
}

.cube8 {
    animation-delay: 0.1s;
}

.cube9 {
    animation-delay: 0.2s;
}

@keyframes cubeGridScaleDelay {
    0%, 70%, 100% {
        transform: scale3D(1, 1, 1);
    }

    35% {
        transform: scale3D(0, 0, 1);
    }
}

.loaderDots {
    position: relative;
    width: 80px;
    margin: 100px auto;
}

.duo {
    height: 20px;
    width: 50px;
    background: hsla(0, 0%, 0%, 0.0);
    position: absolute;
}

.duo, .dot {
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.dot {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #333;
    position: absolute;
}

.dotA {
    left: 0px;
}

.dotB {
    right: 0px;
}

.duo1 {
    left: 0;
    animation-name: dotsSpin;
}

    .duo1 .dotA {
        opacity: 0;
        animation-name: dotsOnOff;
        animation-direction: reverse;
    }

.duo2 {
    left: 30px;
    animation-name: dotsSpin;
    animation-direction: reverse;
}

    .duo2 .dotB {
        animation-name: dotsOnOff;
    }

@keyframes dotsSpin {
    0% {
        transform: rotate(0deg)
    }

    50% {
        transform: rotate(180deg)
    }

    100% {
        transform: rotate(180deg)
    }
}

@keyframes dotsOnOff {
    0% {
        opacity: 0;
    }

    49% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}