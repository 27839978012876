// This gives us intellisense and gets rid of false-positive warnings about variables & mixins
/// <reference path="../node_modules/bootstrap/scss/bootstrap.scss" />

@font-face {
  font-family: OpenSans-Semibold;
  src: url(./assets/fonts/OpenSans/Semibold/OpenSans-Semibold.eot),
    url(./assets/fonts/OpenSans/Semibold/OpenSans-Semibold.ttf) format('truetype'),
    url(./assets/fonts/OpenSans/Semibold/OpenSans-Semibold.woff);
}

@font-face {
  font-family: OpenSans-Light;
  src: url(./assets/fonts/OpenSans/Light/OpenSans-Light.eot),
    url(./assets/fonts/OpenSans/Light/OpenSans-Light.ttf) format('truetype'),
    url(./assets/fonts/OpenSans/Light/OpenSans-Light.woff);
}

@font-face {
  font-family: OpenSans-Regular;
  src: url(./assets/fonts/OpenSans/Regular/OpenSans-Regular.eot),
    url(./assets/fonts/OpenSans/Regular/OpenSans-Regular.ttf) format('truetype'),
    url(./assets/fonts/OpenSans/Regular/OpenSans-Regular.woff);
}

@font-face {
  font-family: OpenSans-Bold;
  src: url(./assets/fonts/OpenSans/Bold/OpenSans-Bold.eot), url(./assets/fonts/OpenSans/Bold/OpenSans-Bold.ttf) format('truetype'),
    url(./assets/fonts/OpenSans/Bold/OpenSans-Bold.woff);
}

:root {
  --ap-green: #24a68b;
  --ap-light-blue: #00a0cd;
  --ap-dark-blue: #007c9f;
  --ap-blue-hover: #017494;
  --ap-gray: #c9cacd;
  --ap-light-gray: #d5d5d6;
  --ap-dark-gray: #656668;
  --ap-gradient-left: #97ca54;
  --ap-gradient-right: #00a9a2;
  --ap-gradient: linear-gradient(235deg, var(--ap-gradient-left), var(--ap-gradient-right));
  --primary-color-green: #006c67;
  --primary-color-gray: #5f6062;
  --secondary-color-green: #5ca39d;
  --secondary-color-gray: #d5d5d6;
  --secondary-color-whitesmoke: #efefef;
  --secondary-color-hovergreen: #00413f;
  --alert-success: #3c763d;
  --alert-success-border: #d6e9cf;
  --alert-success-bg: #dff0d8;
  --alert-danger: #a94442;
  --alert-danger-border: #ebccd1;
  --alert-danger-bg: #f2dede;
  --alert-warning: #d4b718;
  --alert-warning-label: #8a6d3b;
  --alert-warning-border: #faebcc;
  --alert-warning-bg: #fcf8e3;
  --alert-idle-dark: #31708f;
  --alert-idle-medium: #bce8f1;
  --alert-idle-light: #d9edf7;
  --one-select-seagreen-medium: #4dba7a;
  --one-select-seagreen-light: #24a68b;
  --one-select-turqoise: #00a0cd;
  --one-select-yellowgreen: #97ca54;
  --one-select-lavender: #e5eaed;
  --container-shadow: 3px 3px 12px 1px #d5d5d6;
  --container-border: 1px solid #d5d5d6;
  --popup-shadow: 3px 3px 12px 1px rgba(0, 0, 0, 0.4);
  --popup-border: 1px solid rgb(213, 213, 214);
  --summary-shadow: 2px 2px 12px 1px #d5d5d6;
  --summary-border: 1px solid #d5d5d6;
  --button-shadow: 2px 2px 12px 1px #d5d5d6;
  --opensans-light: OpenSans-Light;
  --opensans-semibold: OpenSans-SemiBold;
  --opensans-regular: OpenSans-Regular;
  --opensans-bold: OpenSans-Bold;
  --sm-ghost-white: #f9f9f9;
  --sm-dark-gray: #5e5f61;
}

@import './variables.scss';

// All of the variables above that also exist in bootstrap's scss will
// override bootstrap's defaults
@import 'node_modules/bootstrap/scss/bootstrap';
// Afterwards we can override classes using mixins imported from the
// above line, to further customize certain elements

/*****************************************/
/*Bootstrap classes & vars we're changing*/
/*****************************************/

//fluid-container
.container-fluid {
  //0 removes the padding on left/right
  @include make-container(0px);
}

//buttons
.btn-primary {
  font-family: $font-family-opensans-bold;
  font-size: 19px !important;
  font-weight: 700;
  color: $white;
  margin-bottom: 35px;
  width: 220px;
  height: 42px;
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
  @include button-variant($ap-light-blue, $ap-light-blue);
}

.btn-primary:hover {
  background-color: $ap-blue-hover;
}

.btn-outline-primary {
  font-family: $font-family-opensans-bold;
  font-size: 19px !important;
  font-weight: 700;
  border-width: 2px;
  margin-bottom: 35px;
  width: 220px;
  height: 42px;
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
  @include button-outline-variant($ap-light-blue, #fff, $ap-dark-blue, $ap-dark-blue);
}

.btn-outline-light {
  border-color: $ap-light-gray;
  background-color: $ap-light-gray;
  font-size: 19px !important;
  font-weight: 700;
  color: $ap-dark-gray;
}

.btn-outline-light:hover {
  border-color: $ap-light-gray;
  background-color: $ap-light-gray;
}

.btn-outline-light:focus {
  border-color: #4ed8ff;
  box-shadow: 0 0 0 0.2rem rgba(0, 160, 205, 0.25);
}

.input-group-append button {
  border-top-right-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
}

//card columns
.card-columns {
  @include media-breakpoint-up(md) {
    column-count: 2;
  }

  @include media-breakpoint-down(sm) {
    column-count: 1;
  }
}

//Headers
h1 {
  font-family: $font-family-opensans-bold;

  @include media-breakpoint-down(sm) {
    font-size: $font-size-base * 1.5;
  }
}

h2 {
  font-family: $font-family-opensans-light;
}

h3 {
  font-family: $font-family-opensans;
}

//Text
p {
  font-size: 16px !important;
}

p strong {
  font-family: $font-family-opensans-bold;

  @include media-breakpoint-down(sm) {
    font-size: $font-size-base * 0.875;
  }
}

label {
  font-size: 16px !important;
  color: $sm-dark-gray;
  font-family: $font-family-opensans-semibold;
}

//Links
a {
  font-family: $font-family-opensans-bold;
  color: $ap-dark-blue;

  @include media-breakpoint-down(sm) {
    font-size: $font-size-base * 0.875;
  }

  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: $ap-blue-hover;
}

//Feedback and Alerts
.invalid-feedback {
  font-size: 14px;
  font-family: $font-family-opensans;
  /*color: var(--alert-danger);*/
}

.alert {
  font-size: 16px;
  font-family: $font-family-opensans-semibold;
}

//Breadcrumbs
.breadcrumb-container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: $breadcrumb-bg;
  padding: 0.5rem 0.9375rem 0.65rem;
  height: 74px;
  align-items: center;
  .breadcrumb {
    padding: 0 16px 0 16px;
    margin: 0;

    .component-content {
      width: 100%;

      ol {
        list-style: none;
        padding: 0;

        li {
          float: left;

          .navigation-title {
            //float: left;
            display: inline-block;

            a {
              font-family: $font-family-opensans;
              font-size: 18px;
              line-height: 19px;
            }
          }

          &:last-child {
            .navigation-title {
              a {
                color: $breadcrumb-active-color;
                text-decoration: none;
                cursor: default;
              }
            }
          }
        }
      }
    }
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  font-family: 'FontAwesome';
  content: '\f105' !important;
}

/***********************************/
/*     Custom App Classes Here     */
/***********************************/

.container-shadow {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0 0px 0px 0px $white, 0 15px 0px 0px $white, 12px 0 16px -11px rgba(0, 0, 0, 0.12),
    -12px 0 16px -11px rgba(0, 0, 0, 0.12);
  /*border: var(--container-border);*/
  height: 12em;
  background-color: $white;
  z-index: 0;
}

.content-container {
  border-radius: 10px;
  /* For now, setting bottom padding to 5em so the content container doesn't go directly against the bottom of the page on smaller windows*/
  padding: 2.5em 5em 5em 5em;
  /*background-color: $white;*/
  z-index: 1;
}
